<template>
  <div class="max-w-7xl mx-auto py-6 lg:px-8">
    <div v-if="loading">
      <loading-cards cards="3" />
    </div>
    <div v-else>
      <a-heading> {{ attendee.fullname }} </a-heading>
      <attendee-schedule :attendee="attendee" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoadingCards from '@/components/loaders/schedule-card.vue';
import AttendeeSchedule from '@/components/management/attendees/schedule.vue';
export default {
  components: {
    LoadingCards,
    AttendeeSchedule
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data: () => ({
    loading: true,
    attendee: {},
    schedule: []
  }),
  created() {
    this.fetchAttendee();
  },
  methods: {
    ...mapActions('management', { getAttendee: 'attendee' }),
    async fetchAttendee() {
      try {
        this.attendee = await this.getAttendee(this.id);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>