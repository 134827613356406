<template>
  <div class="max-w-7xl mx-auto py-6 lg:px-8">
    <div v-if="loading">
      <loading-cards cards="3" />
    </div>
    <div v-else>
      <a-heading> {{ total }} Attendees </a-heading>

      <div class="bg-white shadow overflow-hidden sm:rounded-md">
        <ul>
          <attendee
            v-for="(attendee, index) in rows"
            :key="attendee.id"
            :attendee="attendee"
            :class="{'border-t border-gray-200': index > 0}"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { size } from '@/utils/lodash';
import { mapActions } from 'vuex';
import LoadingCards from '@/components/loaders/schedule-card.vue';
import Attendee from '@/components/management/attendees/attendee.vue';
export default {
  components: {
    Attendee,
    LoadingCards
  },
  data: () => ({
    loading: true,
    rows: []
  }),
  computed: {
    total() {
      return size(this.rows);
    }
  },
  created() {
    this.fetchAttendees();
  },
  methods: {
    ...mapActions('management', ['attendees']),
    async fetchAttendees() {
      try {
        let attendees = await this.attendees();
        this.rows = attendees;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>