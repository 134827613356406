<template>
  <div>
    <schedule-card
      v-for="(row, day) in schedule"
      :key="day"
      :day="day"
      :schedule="row"
    />
  </div>
</template>

<script>
import ScheduleCard from './schedule/card.vue';
export default {
  components: {
    ScheduleCard
  },
  props: {
    schedule: {
      type: Object,
      required: true
    }
  }
};
</script>