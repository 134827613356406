<template>
  <li>
    <router-link
      :to="{name: 'management.attendees.schedule', params: {id: attendee.id}}"
      class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
    >
      <div class="flex items-center px-4 py-4 sm:px-6">
        <div class="min-w-0 flex-1 flex items-center">
          <div class="flex-shrink-0">
            <a-avatar
              :hash="attendee.emailHash"
              class="h-12 w-12 rounded-full"
            />
          </div>
          <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <div class="text-sm leading-5 font-medium text-indigo-600 truncate">
                {{ attendee.fullname }}
              </div>
              <div class="mt-2 flex items-center text-sm leading-5 text-gray-500">
                <a-icon
                  name="envelope"
                  size="lg"
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                />
                <span class="truncate">{{ attendee.email }}</span>
              </div>
            </div>
            <div class="hidden md:block">
              <div>
                <div class="text-sm leading-5 text-gray-900">
                  <span
                    class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-blue-100 text-blue-800"
                  >
                    {{ attendee.meetings }} Meetings
                  </span>
                </div>
                <div class="mt-2 flex items-center text-sm leading-5 text-gray-500">
                  {{ attendee.company }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a-icon
            name="angle-right"
            size="lg"
            class="text-gray-400"
          />
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    attendee: {
      type: Object,
      required: true
    }
  }
};
</script>