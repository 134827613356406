<template>
  <schedule
    v-if="scheduleLoaded"
    :schedule="groupedMeetings"
  />
  <div v-else>
    <loading-cards cards="3" />
  </div>
</template>

<script>
import Schedule from '@/components/schedules/user.vue';
import LoadingCards from '@/components/loaders/schedule-card.vue';
import { mapActions } from 'vuex';
import { groupBy } from '@/utils/lodash';
export default {
  components: {
    Schedule,
    LoadingCards
  },
  props: {
    attendee: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    scheduleLoaded: false,
    schedule: []
  }),
  computed: {
    groupedMeetings() {
      return groupBy(this.schedule, (schedule) => schedule.slot.day);
    }
  },
  created() {
    this.fetchSchedule();
  },
  methods: {
    ...mapActions('management', ['attendeeSchedule']),
    async fetchSchedule() {
      try {
        this.schedule = await this.attendeeSchedule(this.attendee.id);
      } catch (e) {
        console.error(e);
      } finally {
        this.scheduleLoaded = true;
      }
    }
  }
};
</script>