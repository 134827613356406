<template>
  <a-card
    :title="title"
    :class="{ 'opacity-50': isOver }"
  >
    <div class="bg-white shadow overflow-hidden sm:rounded-md">
      <meeting
        v-for="meeting in schedule"
        :key="meeting.id"
        :meeting="meeting"
      />
    </div>
  </a-card>
</template>

<script>
import dayjs, { format, isAfter } from '@/utils/dates';
import { last, isEmpty } from '@/utils/lodash';
import Meeting from './meeting.vue';
export default {
  components: {
    Meeting
  },
  props: {
    schedule: {
      type: Object,
      required: true
    },
    day: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    title() {
      return dayjs(this.day).isToday()
        ? 'Todays Meetings'
        : format(this.day, 'dddd MMMM Do YYYY');
    },
    meetings() {
      return this.schedule;
    },
    lastMeeting() {
      return last(this.meetings);
    },
    lastMeetingEnd() {
      return this.lastMeeting.end;
    },
    isOver() {
      return isAfter(this.lastMeetingEnd);
    },
    hasMeetings() {
      return !isEmpty(this.meetings);
    }
  }
};
</script>